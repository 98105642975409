import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import {AppConfigContext} from '../App';
import Recycle from '../Recycle';
import MenuIcon from '../assets/icons/menu.svg';
import logoutIcon from '../assets/icons/log-out.svg';

const Header = (props) => {
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    const handleLogout = () => {
        Recycle.logout();
        appConfig.setState({...appConfig, authenticated: false, initialized: false, user: null});
    }

    const toggleNavBar = () => {
        appConfig.setState({...appConfig, navBarOpen: !appConfig.navBarOpen});
    }

    const renderSearchBar = () => {
        return <div className={classes.searchBar}></div>;
    };

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar style={{backgroundColor: 'white', padding: "0px"}}>
                <div className={classes.logoContainer} onClick={toggleNavBar}>
                    <img src={MenuIcon} alt={""}/>
                    <img className={classes.logo} src={"/logo.png"} alt={""}/>
                </div>
                {renderSearchBar()}
                <div className={classes.rightContainer}>

                    {appConfig.user ? <ProfileButton user={appConfig.user} onLogout={handleLogout}/> : null}
                </div>
            </Toolbar>
        </AppBar>
    )
};

const ProfileButton = ({user, onLogout}) => {
    const classes = useStyles();

    const logout = () => {
        onLogout();
    }

    return (
        <React.Fragment>
            <div className={classes.profile}>
                <div className={classes.fullName}>Hello <strong>{user.name}</strong></div>
                <div className={classes.role}>{user.role.toUpperCase().replace(/_/g, ' ')}</div>
            </div>
            <ButtonBase className={classes.profileButtonContainer} onClick={logout}>
                <img src={logoutIcon} alt={""}/>
                {/*<ExitToAppIcon color="primary" />*/}
            </ButtonBase>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderBottom: "1px solid #F3F5FA"
    },
    logoContainer: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        maxWidth: "246px",
        width: "246px",
        paddingLeft: "24px",
        cursor: "pointer",
        borderRight: "1px solid #F3F5FA"
    },
    logo: {
        paddingLeft: "25px",
        height: '56px'
    },
    rightContainer: {
        display: 'flex',
        paddingRight: "24px"
    },
    profileButtonContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    profile: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize',
        textAlign: 'left'
    },
    role: {
        color: '#999999',
        fontSize: '9px'
    },
    fullName: {
        color: theme.palette.primary.main,
        fontSize: '12px'
    },
    divider: {
        height: '24px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    bellContainer: {
        marginRight: theme.spacing(3)
    },
    searchBar: {
        display: "flex",
        alignItems: "center",
        flex: 1,
        color: "#CCCCCC",
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "15px",
        width: "100%",
        height: "100%"
    },
    searchText: {
        marginLeft: "5px",
        width: "100%"
    },
    searchIcon: {
        width: "20px",
        marginLeft: "5px"
    }
}));

export default Header;