import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory, useLocation} from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import DashboardIcon from '../assets/icons/dashboard_icon.png'
import searchIcon from '../assets/icons/search.svg';

let icons = {
    dashboardIcon: DashboardIcon,
    searchIcon: searchIcon
}

const SideNavBar = ({menu, className}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const isActivePath = (path) => {
        const basePath = location.pathname.split('/')[1];
        return `/${basePath}` === path;
    }

    const renderNavigations = () => {
        return menu.map((item) => {
            const activePath = isActivePath(item.to);
            const classNames = clsx({[classes.inactive]: true, [classes.active]: activePath});

            const renderIcon = () => {
                if (item.icon.indexOf("custom:") > -1){
                    return (
                        <img src={icons[item.icon.replace('custom:','')]} alt={""}/>
                    );
                }
                return (
                    <Icon>{item.icon}</Icon>
                );
            };

            return (
                <ListItem
                    key={item.id}
                    onClick={() => history.push(item.to)}
                    button
                    className={classes.listItem}
                >
                    {activePath ? <div className={classes.activeBox}/> : null}
                    <ListItemIcon className={classNames}>
                        {renderIcon()}
                    </ListItemIcon>
                    <ListItemText className={classNames} primary={item.name}/>
                </ListItem>
            )
        });
    }

    return (
        <div className={className}>
            <div className={classes.root}>
                <List component="nav" className={classes.listItems}>
                    {renderNavigations()}
                </List>
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#ffffff",
        marginLeft: "10px"
    },
    activeBox: {
        height: '100%',
        width: '3px',
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        left: 0,
    },
    inactive: {
        color: '#666666'
    },
    active: {
        color: theme.palette.primary.main
    },
    listItem: {
        height: "50px",
        minHeight: "50px"
    },
    listItems: {
        marginTop: "30px",
        marginBottom: "8px",
        paddingTop: "0px",
        paddingBottom: "0px"
    }

}))
;

export default SideNavBar;