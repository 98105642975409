import React, {useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import CheckCirlceIcon from "@material-ui/icons/CheckCircle";
import {AppConfigContext} from "../App";


const SuccessCard = () => {
    const appConfig = useContext(AppConfigContext);
    const {setState, showSuccess} = appConfig;
    const classes = useStyles();

    useEffect(()=>{
        if (appConfig.showSuccess){
            setTimeout(()=>{
                setState({...appConfig, showSuccess: false})
            }, 3000)
        }

    }, [setState, showSuccess, appConfig])

    return (
        <Paper className={classes.container}>
            <CheckCirlceIcon fontSize="large" className={classes.checkIcon}/>
            <div className={classes.title}>Successfully Saved</div>
        </Paper>
    )
};

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: `${theme.spacing(2)}px`,
        padding: theme.spacing(3)
    },
    checkIcon: {
        color: theme.palette.success.main
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '20px',
    },
}));

export default SuccessCard;