import React, {useCallback, useEffect, useState, useContext} from 'react';
import MaterialTable from '@material-table/core';
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import styled from 'styled-components';
import theme from '../../theme';
import Paginator from "../../components/table/paginator";
import { AppConfigContext } from '../../App';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Paper} from "@material-ui/core";


const tableTheme = createTheme({
    ...theme,
    overrides: {
        MuiFormControl: {
            root: {
                width: "50%"
            }
        },
        MuiToolbar: {
            root: {
                backgroundColor: "#5555fa",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
                color: "#ffffff"
            }
        },
        MuiPaper: {
            root: {
                borderRadius: "10px !important"
            }
        },
        MuiTableCell: {
            root: {
                zIndex: "auto !important",
            },
        },
    }
});

const useStyles = makeStyles({
  table: {
    "& tbody tr:last-child td": {
      border: 0
    }
  }
});


const CustomizedTable = ({config, props, columns}) => {
    const {mockData} = useContext(AppConfigContext);
    const tableRef = React.createRef();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, ] = React.useState(5);
    const [rows, setRows] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [orderBy, setOrderBy] = React.useState(-1)
    const [orderDirection, setOrderDirection] = React.useState("")
    const [pendingRequest, setPendingRequest] = useState(false);
    const classes = useStyles();

    useEffect(()=>{
        return () => {
            setLoaded(false)
        }
    },[])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchData = useCallback(async (showLoader = true) => {
        if (!props.model || !props.method) return;
        if (pendingRequest || !loaded) return;
        switch (props.method) {
            case "get_new_and_updated_cases":
                setRows(mockData.filter(({assigned_agent}) => assigned_agent));
                break;
            case "get_follow_up_cases":
                setRows(mockData.filter(({assigned_agent}) => !assigned_agent));
                break;
            default:
                console.log("No data for model: ", props.method);
                break;
        }
    },[loaded, setPendingRequest, setLoaded, mockData]);

    React.useEffect(() => {
        fetchData();
        
        if (props.pollingInterval) {
            const interval = setInterval(() => {
                fetchData(false);
            }, props.pollingInterval);

            return (() => clearInterval(interval))
        }
    }, [props, fetchData]);

    React.useEffect(() => {
        if (props.manuallyInjectedData === undefined) return;
        setRows(props.manuallyInjectedData)
    }, [props.manuallyInjectedData])


    let overrides = {
        Container: props => <Paper {...props} className={classes.table} />,
        Pagination: props => null
    }
    if (props.disableToolbar){
        overrides['Toolbar'] = props => null
    }

    const TABLE_OVERRIDES = {
        ...overrides,
        ...(props?.componentOverrides || {})
    }

    const updateOrderBy = (newOrderBy, newOrderDirection) => {
        setOrderBy(newOrderBy);
        setOrderDirection(newOrderDirection);
    }

    const TABLE_OPTIONS ={
        filtering: props?.enableFiltering !== undefined ? props.enableFiltering : true,
        headerStyle: {
            backgroundColor: "#F7F9F2",
            color: '#000000'
        },
        onOrderChange: updateOrderBy,
        actionsColumnIndex: -1,
        minBodyHeight: '100%',
        showTitle: props?.enableSearch !== undefined ? !props.enableSearch : false,
        search: props?.enableSearch !== undefined ? props.enableSearch : true,
        searchFieldAlignment: "left",
        searchFieldStyle: {
            borderRadius: "20px",
            backgroundColor: "#FFFFFF"
        },
        searchFieldVariant: "outlined",
        showFirstLastPageButtons: false,
        paging: true,
        emptyRowsWhenPaging: false
    }

    const renderTableTitle = () => {
        if (props?.enableSearch === undefined || props?.enableSearch === true)
            return <TableTitle>{config.title || props.title || "Table"}</TableTitle>
        return null;
    }

    React.useEffect(() => {
        if (!tableRef.current || !rows)
            return;

        tableRef.current.dataManager.setData(rows);
        tableRef.current.onChangeOrder(orderBy, orderDirection)
        tableRef.current.onChangePage(null, page)

        // NB: page, orderBy and orderDirection intentionally left out of dep array
    }, [tableRef, rows])

    return (
        <React.Fragment>

            <MuiThemeProvider theme={tableTheme}>
                {renderTableTitle()}
                <MaterialTable
                    tableRef={tableRef}
                    data={[]}
                    columns={columns}
                    title={config?.title || props?.title || "Table"}
                    onChangePage={(page, pageSize) => setPage(page)}
                    localization={{
                        toolbar: {
                            searchPlaceholder: config.search_text || props.search_text || "Search"
                        }
                    }}
                    components={TABLE_OVERRIDES}
                    options={TABLE_OPTIONS}
                />
            </MuiThemeProvider>
            <Paginator
                count={rows.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
            />
        </React.Fragment>
    )
};


export default CustomizedTable;



const TableTitle = styled.p`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: uppercase;
`;

