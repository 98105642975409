import React, {useContext} from "react";
import styled from 'styled-components';
import {Button, ButtonGroup} from "@material-ui/core";
import {AppConfigContext} from "../../App";

const ProcessButton = ({rowData}) => {
    const appConfig = useContext(AppConfigContext);
    const { assigned_agent } = rowData;
    if (!assigned_agent)
        return (
            <ButtonGroup>
                <Button
                    variant="outlined"
                    color={"primary"}
                    onClick={() => {
                        const newMockData = appConfig.mockData.map((d) => {
                            if (d.id !== rowData.id) return d;
                            return {...d, assigned_agent: appConfig.user.name}}
                        )
                        appConfig.setState({...appConfig, mockData: newMockData})
                    }}
                >
                    Take Case
                </Button>
            </ButtonGroup>
        )

    const hasChat = Object.keys(appConfig.chats || {}).includes(`${rowData.id}`);
    return (
        <ButtonGroup>
        <Button
            variant={"contained"}
            disableElevation
            onClick={() => {
                if (hasChat) {
                    appConfig.setState({...appConfig, selectedChat: rowData.id})
                    return;
                }
                const transformedMessageList = rowData.message_log.map((m) => {
                    if (m.from === "system") return {author: "me", type: "text", data: {text: m.message}}
                    return {author: "them", type: "text", data: {text: m.message}}
                })
                appConfig.setState({...appConfig, chats: {...appConfig.chats, [rowData.id]: {
                    id: rowData.id,
                    name: `${rowData.first_name} ${rowData.last_name}`,
                    status: rowData.status,
                    messageList: transformedMessageList,
                    newMessagesCount: 0,
                    isOpen: true,
                }},
                selectedChat: rowData.id
                })
            }}
            color={"primary"}>
            Open Chat
        </Button>
        <Button
            variant={"outlined"}
            onClick={() => {
                const newMockData = appConfig.mockData.filter((d) => d.id !== rowData.id)
                const newChats = {...appConfig.chats}
                delete newChats[rowData.id]
                appConfig.setState({...appConfig, chats: newChats, mockData: newMockData, selectedChat: null})
            }}
            color={"secondary"}>
                Close
        </Button>    
        </ButtonGroup>
    );
}

export const newUpdatedCasesColumns = [
    {
        field: 'first_name',
        title: 'Name',
        filtering: false,
        headerStyle: {
            width: '60px'
        },
        render: (rowData) =>
            <NameNationality>
                <Name>{`${rowData.first_name} ${rowData.last_name}`}</Name>
            </NameNationality>,
        customFilterAndSearch: (term, rowData) => {
            let termInName = rowData.first_name.toLowerCase().includes(term.toLowerCase())
            let termInSurname = rowData.last_name.toLowerCase().includes(term.toLowerCase())
            return termInSurname || termInName
        },
    },
    {
        field: 'cellphone_number',
        title: 'Contact No.',
        filtering: false,
        render: rowData => <GeneralTextDiv>{rowData.cellphone_number}</GeneralTextDiv>
    },
    {
        field: 'facility',
        title: 'Facility',
        filtering: false,
        render: rowData => <GeneralTextDiv>{rowData.facility}</GeneralTextDiv>
    },
    {
        field: 'ticket_classification',
        title: 'Ticket Classification',
        filtering: false,
        render: rowData => <GeneralTextDiv>{rowData.ticket_classification}</GeneralTextDiv>
    },
    {
        field: 'assigned_agent',
        title: 'Assigned Agent',
        filtering: false,
        render: ({assigned_agent}) => {
            if (!assigned_agent)
                return <GeneralTextDiv>Not assigned</GeneralTextDiv>
            return <GeneralTextDiv><b>{assigned_agent}</b></GeneralTextDiv>
        }
    },
    {
        field: 'last_message_received',
        title: 'Last Message Received',
        filtering: false,
        render: ({message_log}) => {
            if (!message_log || message_log.length === 0)
                return <GeneralTextDiv>No message from user</GeneralTextDiv>
            else {
                const last_message_received = message_log.filter(m => m.to === "system")
                    .sort((a, b) => new Date(b.sent_at) - new Date(a.sent_at))[0]
                return <GeneralTextDiv>{last_message_received.message}</GeneralTextDiv>
            }
        },
    },
    {
        field: 'status_updated_at',
        title: 'Last Update At',
        filtering: false,
        render: rowData =>{
            const now = new Date()
            if (now - new Date(rowData.status_updated_at) > 1000 * 60 * 60 * 24)
                return (
                    <DateStatus>
                        <DateDiv>{new Date(rowData.status_updated_at).toLocaleString()}</DateDiv>
                    </DateStatus>)
            return (
            <DateStatus>
                <DateDiv>{new Date(rowData.status_updated_at).toLocaleTimeString()}</DateDiv>
            </DateStatus>)
        },
        customFilterAndSearch: (term, rowData) => {
            let termInDate = new Date(rowData.status_updated_at).toLocaleString().includes(term.toLowerCase())
            return termInDate
        },
        customSort: (a, b) => (new Date(a.status_updated_at)) > (new Date(b.status_updated_at)) ? -1 : 1,
    },
    {
        field: 'actions',
        title: 'Action',
        filtering: false,
        render: rowData => <ProcessButton rowData={rowData}/>
    },
]

const NameNationality = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Name = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: bold;
`;


const DateStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DateDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;

const GeneralTextDiv = styled.div`
  color: #6B6B6B;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`;