import React from 'react';
import {useHistory} from "react-router-dom";
import get from 'lodash.get';

const Redirect = ({props}) => {

    const history = useHistory();

    React.useEffect(() => {
        const value = get(props, props.conditionParameter, false);
        const operator = props.conditionOperator;
        const conditionValue = props.conditionValue;

        let requires_redirect = false;
        if (operator === '==') {
            requires_redirect = (value === conditionValue);
        } else if (operator === '!=') {
            requires_redirect = (value !== conditionValue);
        } else if (operator === 'defined') {
            requires_redirect = (value !== undefined && value !== null);
        }

        if (requires_redirect) {
            history.push(props.to);
        }
    })

    return null
};


export default Redirect;