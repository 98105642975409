import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const MetricCard = ({status}) => {
    const classes = useStyles();
    const className = null;

    const primaryMetric = () => {
        return (
            <div className={classes.primaryMetric}>
                <div className={classes.primaryMetricCount}>
                    {status.primary.value}
                </div>

                <div className={classes.primaryMetricStatus}>
                    {status.primary.name}
                </div>
            </div>
        )
    };

    const secondaryMetric = () => {
        return (
            <div className={classes.secondaryMetric}>
                <div className={classes.secondaryMetricCount}>
                    {status.secondary.value}
                </div>

                <div className={classes.secondaryMetricStatus}>
                    {status.secondary.name}
                </div>
            </div>
        )
    };

    return (
        <div className={`${classes.root} ${className}`}>
            {primaryMetric()}
            {secondaryMetric()}
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: "center",
        boxSizing: "border-box",
        height: "74px",
        border: "2px solid #F3F5FA",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF"
    },
    primaryMetric: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    primaryMetricCount: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "10px",
        height: "44px",
        color: "#333333",
        fontSize: "36px",
        letterSpacing: "-0.55px",
        lineHeight: "44px",
    },
    primaryMetricStatus: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "34px",
        color: "#333333",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "17px",
        textTransform: "uppercase",
        wordSpacing: "43px"
    },
    secondaryMetric: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
        flexDirection: "row",
        height: "54px",
        borderRadius: "5px",
        background: "linear-gradient(134.1deg, #A8CE38 0%, #0C7C59 100%)"
    },
    secondaryMetricCount: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "10px",
        height: "44px",
        color: "#FFFFFF",
        fontSize: "36px",
        fontWeight: "bold",
        letterSpacing: "-0.55px",
        lineHeight: "44px"
    },
    secondaryMetricStatus: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "34px",
        color: "#FFFFFF",
        fontSize: "14px",
        letterSpacing: "0",
        lineHeight: "17px",
        textTransform: "capitalize",
        wordSpacing: "69px"
    }
});

MetricCard.propTypes = {
};

MetricCard.defaultProps = {
    elevation: 0
};

export default MetricCard;