import React from 'react';
import CustomizedTable from "../../components/table";
import {newUpdatedCasesColumns} from "./newUpdatedCasesColumns";


const columns = {
    agent_dashboard_columns: newUpdatedCasesColumns
};

const Table = ({config, props}) => {

    return (
        <CustomizedTable
            config={config}
            props={props}
            columns={columns[config.columns || props.columns]}
        />
    )
};


export default Table;
