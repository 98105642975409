import React, {useContext} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import SideNavBar from './SideNavBar';
import Header from './Header';
import Widget from '../widgets';
import { AppConfigContext } from "../App";
import { Launcher } from '@wealize/react-chat-window';

const Page = React.memo(({config}) => {
    return React.createElement(React.memo(Widget), {config});
});

const Base = ({pages, menu}) => {
    const appConfig = useContext(AppConfigContext);
    const classes = useStyles();

    const chatId = appConfig?.selectedChat;
    let chat = null;
    if (chatId) {
        chat = appConfig?.chats?.[chatId];
    }

    const appendMessage = (message) => {
        const newMessageList = [...chat?.messageList || [], message];
        chat.messageList = newMessageList;
        appConfig.setState({...appConfig, chats: {...appConfig.chats, [chatId]: {...appConfig.chats[chatId], messageList: newMessageList}}})
    }

    const handleClick = () => {
        appConfig.setState({
            ...appConfig,
            chats: {
                ...appConfig?.chats || {},
                [chatId]: {
                    ...chat || {},
                    isOpen: (!appConfig?.chat?.[chatId]?.isOpen) || true
                }
            }
        })
    }

    const appendMessageTest = (message) => {
        appendMessage(message)
        setTimeout(() => {
            appendMessage({author: 'them', type: 'text', data: {text: 'Thank you for the assistance'}})
        }, 3000)
    }
    return (
        <Router>
            <div className={classes.root}>
                <Header/>

                <div className={classes.container}>
                    {appConfig.navBarOpen ? <SideNavBar className={classes.nav} menu={menu}/> : null}

                    <div className={classes.content}>
                        <Switch>
                            {
                                pages.map(page => <Route basename={page.id} path={page.path} key={page.id}
                                                            exact><Page
                                    config={page.widget}/></Route>)
                            }
                            <Route path="/">
                                <Redirect to={"/dashboard"}/>
                            </Route>
                        </Switch>
                    </div>
                    {chat && <Launcher
                            key={chatId}
                            agentProfile={{
                                teamName: `Chat with ${chat.name}`,
                            }}
                            onFilesSelected={() => {}}
                            onMessageWasSent={appendMessageTest}
                            messageList={chat?.messageList || []}
                            newMessagesCount={chat?.newMessagesCount || 0}
                            handleReadMessages={(m) => {}}
                            handleClick={handleClick}
                            isOpen={chat?.isOpen || false}
                            showEmoji
                            verticalQuickReplies={false}
                        />
                    }
                </div>
            </div>
        </Router>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: "#f3f3f9",
        maxHeight: '100%',
        overflow: 'hidden'
    },
    nav: {
        display: "inline-block",
        width: '246px'
    },
    content: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        overflow: 'auto',

        // Firefox
        scrollbarWidth: 'thin',
        scrollbarColor: '#999 #fff',

        // Chrome
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            background: '#fff'
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#999'
        },

    }
}));

export default Base;