import { App, Auth0 } from 'recycle-frontend-core';

export { interpolate, getObjectByPath, evaluatePolishExpression } from 'recycle-frontend-core'

const authProvider = new Auth0({
    grant_type: 'password',
    audience: 'https://agent-portal.tshiamisotrust.co.za',
    scope: 'openid',
    client_id: process.env.REACT_APP_CLIENT_ID,
    endpoint: 'https://a2d24.eu.auth0.com',
    connection: 'Username-Password-Authentication'
});
const auth = new App(authProvider, "");
export default auth;