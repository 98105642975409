import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import AccountIcon from '@material-ui/icons/AccountCircle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';
import { AppConfigContext } from './App';
import Recycle from './Recycle';



const Login = () => {
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const [showUsername, setShowUsername] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleUsernameChange = (value) => {
        setUsername(value);
    }

    const handlePasswordChange = (value) => {
        setPassword(value)
    }

    const showPassword = () => {
        setShowUsername(false);
    }

    const hidePassword = () => {
        setError('');
        setShowUsername(true);
    }

    const login = () => {
        setSubmitting(true);
        setError('');
        Recycle.login(username, password).then(response => {
            if (response.result === 200) {
                setSubmitting(false);
                appConfig.setState({ ...appConfig, authenticated: true });
            }
            else {
                setSubmitting(false);
                setError('Invalid Credentials')
            }
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>

                <LinearProgress style={{ visibility: submitting ? 'visible' : 'hidden' }} />

                <div className={classes.contentContainer}>

                    <img className={classes.logo} src="/logo.png" alt="logo" />
                    {
                        showUsername ?
                            <Username value={username} onNext={showPassword} onChange={handleUsernameChange} /> :
                            <Password
                                value={password}
                                username={username}
                                onChange={handlePasswordChange}
                                onNext={login}
                                onBack={hidePassword}
                                submitting={submitting}
                                error={error}
                            />
                    }
                </div>
            </div >
        </div>
    )
}

const Username = ({ value, onChange, onNext }) => {
    const classes = useStyles();

    return (
        <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={300}>
            <div className={classes.content}>
                <div className={classes.title}>Sign in</div>
                <div className={classes.subTitle}>Continue to Pre-Lodgement Agent Portal</div>

                <TextField
                    className={classes.input}
                    label="Username"
                    variant="outlined"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            onNext();
                        }
                    }}
                    autoFocus
                />

                <div className={classes.buttonContainer}>
                    <Button variant="contained" color="primary" onClick={onNext} disabled={!(value.length > 0)}>Next</Button>
                </div>
            </div>
        </Slide>
    )
}

const Password = ({ value, username, onChange, onNext, onBack, submitting, error }) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [requestingPasswordReset, setRequestingPasswordReset] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleForgotPassword = () => {
        setRequestingPasswordReset(true);
        Recycle.authProvider.resetPassword(username)
            .then(enqueueSnackbar("We've sent you an email to reset your password", { variant: "success" }))
            .finally(setRequestingPasswordReset(false));
    }

    return (
        <React.Fragment>
            <Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={300}>
                <div className={classes.content}>
                    <div className={classes.title}>Welcome</div>

                    <div onClick={onBack} className={clsx([classes.subTitle, classes.usernameButton])}>
                        <AccountIcon />
                        {username}
                    </div>

                    <TextField
                        className={classes.input}
                        label="Enter your password"
                        type={showPassword ? "text" : "password"}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        error={error ? true : false}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                onNext();
                            }
                        }}
                        helperText={error}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )

                        }}
                        variant="outlined"
                        autoFocus
                    />

                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="primary" onClick={onNext} disabled={submitting || value.length === 0}>Next</Button>
                        <Button color="primary" onClick={handleForgotPassword} disabled={requestingPasswordReset}>Forgot password?</Button>
                    </div>
                </div>
            </Slide>

        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
        border: '1px solid #dadce0',
        borderRadius: '8px',
        width: '450px',
    },
    contentContainer: {
        height: 'auto',
        minHeight: '400px',
        padding: '48px 40px 36px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: '.2s'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.primary.main,
        width: '100%'
    },
    title: {
        fontSize: '24px',
        marginTop: theme.spacing(2),
        fontWeight: 400
    },
    subTitle: {
        fontSize: theme.spacing(2),
        fontWeight: 400,
        marginTop: theme.spacing(1)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(3),
        justifyContent: 'space-between'
    },
    input: {
        marginTop: theme.spacing(3)
    },
    usernameButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: 'rgba(29, 52, 64, 0.04)'
        },
        border: '1px solid #dadce0',
        borderRadius: theme.spacing(2),
        cursor: 'pointer',
        padding: 6
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkIcon: {
        color: theme.palette.success.main
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default Login;